<script setup>
import { reactive, watchEffect, computed } from "vue";
import { useStore } from "vuex";

import NextBtnItem from "@/components/common/NextBtnItem.vue";
import BackBtnItem from "@/components/common/BackBtnItem.vue";

let basicDetails = reactive({
  lastName: "",
  firstName: "",
  middleName: "",
  birthMonth: "",
  birthDay: "",
  birthYear: "",
  age: "",
  gender: "",
  pwd: false,
  address: "",
  weight: "",
  height: "",
  mobile: "",
  email: "",
});

const birth_date = computed(() => {
  if (
    basicDetails.birthYear &&
    basicDetails.birthMonth &&
    basicDetails.birthDay
  ) {
    const formattedMonth = basicDetails.birthMonth.toString().padStart(2, "0");
    const formattedDay = basicDetails.birthDay.toString().padStart(2, "0");
    return `${basicDetails.birthYear}-${formattedMonth}-${formattedDay}`;
  }
  return "";
});

const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const years = [];
const currentYear = new Date().getFullYear();

for (let year = currentYear - 10; year <= currentYear + 10; year++) {
  years.push(year);
}

const daysInMonth = reactive([]);

const updateDays = () => {
  if (basicDetails.birthMonth && basicDetails.birthYear) {
    const days = new Date(
      basicDetails.birthYear,
      basicDetails.birthMonth,
      0
    ).getDate();
    daysInMonth.length = 0;
    for (let day = 1; day <= days; day++) {
      const formattedDay = day < 10 ? `0${day}` : `${day}`;
      daysInMonth.push(formattedDay);
    }
    if (basicDetails.birthDay > days) {
      basicDetails.birthDay = "";
    }
  }
};

watchEffect(updateDays);

const store = useStore();
const done = () => {
  store.commit("setBasicDetails", basicDetails, birth_date);
};

const getBasicDetailsLocalStorage = () => {
  const data = localStorage.getItem("basic_details");
  if (data) {
    const parsedData = JSON.parse(data);
    basicDetails = { ...basicDetails, ...parsedData };
  }
};
getBasicDetailsLocalStorage();
</script>

<template>
  <div class="card">
    <div class="card-body">
      <div class="row mb-3">
        <h3 class="text-primary fw-bold">Basic Details</h3>
        <div class="col-4">
          <label for="" class="form-label">Last Name</label>
          <input
            type="text"
            class="form-control"
            v-model="basicDetails.lastName"
          />
        </div>
        <div class="col-4">
          <label for="" class="form-label">First Name</label>
          <input
            type="text"
            class="form-control"
            v-model="basicDetails.firstName"
          />
        </div>
        <div class="col-4">
          <label for="" class="form-label">Middle Name</label>
          <input
            type="text"
            class="form-control"
            v-model="basicDetails.middleName"
          />
        </div>
      </div>
      <div class="row mb-5">
        <div class="col-6">
          <label class="form-label">Date of Birth</label>
          <div class="d-flex">
            <div class="flex-fill me-1">
              <select
                id="yearSelect"
                v-model="basicDetails.birthYear"
                @change="updateDays"
                class="form-select"
              >
                <option value="" disabled>Select Year</option>
                <option v-for="year in years" :key="year" :value="year">
                  {{ year }}
                </option>
              </select>
            </div>
            <div class="flex-fill me-1">
              <select
                id="monthSelect"
                v-model="basicDetails.birthMonth"
                @change="updateDays"
                class="form-select"
              >
                <option value="" disabled>Select Month</option>
                <option
                  v-for="(month, index) in months"
                  :key="index"
                  :value="index + 1"
                >
                  {{ month }}
                </option>
              </select>
            </div>
            <div class="flex-fill me-1">
              <select
                id="daySelect"
                v-model="basicDetails.birthDay"
                class="form-select"
              >
                <option value="" disabled>Select Day</option>
                <option v-for="day in daysInMonth" :key="day" :value="day">
                  {{ day }}
                </option>
              </select>
            </div>
          </div>
        </div>
        <div class="col-2">
          <label for="" class="form-label">Age</label>
          <input
            type="number"
            class="form-control"
            v-model="basicDetails.age"
          />
        </div>
        <div class="col-4">
          <label for="" class="form-label">Gender</label>
          <div class="d-flex">
            <div class="flex-fill">
              <div class="form-check">
                <input
                  id="maleRadio"
                  name="genderRadio"
                  type="radio"
                  class="form-check-input"
                  value="M"
                  v-model="basicDetails.gender"
                />
                <label for="maleRadio">Male</label>
              </div>
            </div>
            <div class="flex-fill">
              <div class="form-check">
                <input
                  id="femaleRadio"
                  name="genderRadio"
                  type="radio"
                  class="form-check-input"
                  value="F"
                  v-model="basicDetails.gender"
                />
                <label for="femaleRadio">Female</label>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row mb-3">
        <div class="col-7">
          <h3 class="text-primary fw-bold">Address</h3>
          <label for="" class="form-label">Full Address</label>
          <input
            type="text"
            class="form-control"
            v-model="basicDetails.address"
          />
        </div>
        <div class="col-5">
          <h3 class="text-primary fw-bold">Anthropometry</h3>
          <div class="d-flex">
            <div class="me-5">
              <label for="" class="form-label">Weight (Kg)</label>
              <input
                type="number"
                class="form-control"
                v-model="basicDetails.weight"
              />
            </div>
            <div>
              <label for="" class="form-label">Height (cm)</label>
              <input
                type="number"
                class="form-control"
                v-model="basicDetails.height"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="row mb-3">
        <h3 class="text-primary fw-bold">Contact Details</h3>
        <div class="col-7">
          <label for="" class="form-label">Mobile Number</label>
          <input
            type="text"
            class="form-control"
            v-model="basicDetails.mobile"
          />
        </div>
        <div class="col-5">
          <label for="" class="form-label">Email Address</label>
          <input
            type="email"
            class="form-control"
            v-model="basicDetails.email"
          />
        </div>
      </div>
      <div class="d-flex mb-3">
        <div class="me-2">
          <BackBtnItem next="/pre-assessment" />
        </div>
        <NextBtnItem @click="done" next="/payment-summary" />
      </div>
    </div>
  </div>
</template>
