<script setup></script>

<template>
  <nav class="d-none d-md-block">
    <img class="logo" src="../../assets/images/Logo.svg" alt="" />
  </nav>
</template>

<style scoped>
nav {
  padding: 20px 10px;
  border-bottom: 2px outset rgba(0, 0, 0, 0.2);
  background-color: rgba(255, 255, 255, 1);
}

.logo {
  margin-left: 5%;
  max-width: 10%;
}
</style>
