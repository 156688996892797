<template>
  <router-view />
</template>

<script setup>
import { useStore } from "vuex";
import { ref, onMounted, onBeforeUnmount } from "vue";

// import axios from "axios";

const store = useStore();
const tokenCheckInterval = ref(null);

store.commit("initializeStore"); //will run the function in store initializesStore
if (store.state.jwt_access_token) {
  //if the token exists
  console.log("initialize token");

  // axios.defaults.headers.common["Authorization"] =
  // "Bearer " + store.state.jwt_access_token; //token will be added automatically everytime we use axios WARNING!!! DO NOT FORGET TO USE SPACE IN AFTER TOKEN
} else {
  console.log("no token");
  // axios.defaults.headers.common["Authorization"] = ""; //not authenticated
}

onMounted(() => {
  if (store.state.jwt_access_token) {
    tokenCheckInterval.value = setInterval(() => {
      console.log("worked");
      store.dispatch("inspectToken");
    }, 10000);
  }
});

onBeforeUnmount(() => {
  console.log("cleared");
  clearInterval(tokenCheckInterval.value);
});
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
