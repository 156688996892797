<template>
  <MenuBarItem />

  <div class="main-container">
    <div class="container-sm">
      <div class="otp-container col-lg-3 col-sm-12 mx-auto pt-5 pt-lg-0">
        <div
          class="card p-3 pt-4 w-auto mb-3"
          style="border-radius: 10px; background: rgba(255, 255, 255, 0.6)"
        >
          <div class="card-body">
            <form @submit.prevent="submitForm">
              <p class="text-primary fs-5 fw-bold mb-0">Registration Code</p>
              <p class="fs-6 mb-4">
                Enter the 6-digit registration code sent to your mobile number.
              </p>
              <div class="form-group mb-3">
                <input
                  oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                  type="text"
                  v-model="otp"
                  placeholder="Enter 6-Digit Code"
                  class="form-control"
                  maxlength="6"
                  pattern="[0-9]*"
                />
              </div>

              <div class="d-grid gap-2">
                <button class="btn btn-primary mt-2">Continue</button>
              </div>
            </form>
          </div>
        </div>
        <div class="text-center">
          <a href="" class="link-primary mx-auto"
            >Didn’t get the 6-digit code?</a
          >
          <button
            class="btn btn-outline-primary mx-auto mt-4"
            style="display: block; min-width: 250px"
            @click="handleResendOTP"
            :disabled="isCooldown"
          >
            {{ buttonText }}
          </button>
          <div v-if="isCooldown">Cooldown: {{ cooldownTime }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MenuBarItem from "../components/homepage/MenuBarItem.vue";
import { ref, watch, computed } from "vue";
import axios from "axios";
import { useRouter } from "vue-router";

// import { useRouter } from "vue-router";
export default {
  name: "ReturningPatientPage",

  components: {
    MenuBarItem,
  },
  setup() {
    const otp = ref("");

    const router = useRouter();

    const submitForm = async () => {
      const value = otp.value;
      try {
        const response = await axios.post(
          `https://admin.cas.casenote-md.com/api/v1/routee_auth/otp_login_recipient/?recipient_type=${value}`
        );
        console.log(response);
      } catch (error) {
        console.log(error);
      }
      router.push("/profile");
    };

    const cooldownDuration = 300;
    const isCooldown = ref(false);
    const cooldownTime = ref(0);

    const buttonText = computed(() => {
      return isCooldown.value
        ? `Resend OTP (${cooldownTime.value})`
        : "Resend OTP";
    });

    const handleResendOTP = () => {
      if (!isCooldown.value) {
        isCooldown.value = true;
        cooldownTime.value = cooldownDuration;

        const interval = setInterval(() => {
          cooldownTime.value -= 1;
          if (cooldownTime.value === 0) {
            clearInterval(interval);
            isCooldown.value = false;
          }
        }, 1000);
      }
    };

    watch(otp, (newValue, oldValue) => {
      if (newValue !== oldValue) {
        otp.value = newValue.replace(/\D/g, "");
      }
    });

    return {
      submitForm,
      otp,
      handleResendOTP,
      buttonText,
    };
  },
};
</script>

<style scoped>
.main-container {
  background-image: url("../assets/images/BG.jpg");
  min-height: 800px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

@media (min-width: 576px) {
  .main-container {
    background-image: url("../assets/images/BG.jpg");
    min-height: 800px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
}

@media (min-width: 992px) {
  .otp-container {
    position: absolute;
    top: 30%;
    left: 10%;
  }
}
</style>
