import { createStore } from "vuex";
import axios from "axios";
import jwt_decode from "jwt-decode";
import router from "@/router";

export default createStore({
  state: {
    configs: {
      //https://admin.cas.casenote-md.com/
      //https://admin-cas.casenote-md.com/
      //https://admin-cas.mobile.casenote-md.com/
      base_url: "https://admin-cas.mobile.casenote-md.com/",
      api_url: "api/v1/",
    },
    isPhoneNumber: false,
    isAuthenticated: false,
    jwt_access_token: localStorage.getItem("access_token"),
    jwt_refresh_token: localStorage.getItem("refresh_token"),

    preAssessmentDetails: {
      primary_symptoms: "",
      current_medical_condition: "",
      allergies: "",
      drink_alcohol: "",
      current_medications: "",
      smoker: false,
    },
    basicDetails: {
      lastName: "",
      firstName: "",
      middleName: "",
      birthMonth: "",
      birthDay: "",
      birthYear: "",
      age: "",
      gender: "",
      pwd: false,
      address: "",
      weight: "",
      height: "",
      mobile: "",
      email: "",
    },
  },

  getters: {
    getConfigs: (state) => state.configs,
  },
  mutations: {
    initializeStore(state) {
      if (localStorage.getItem("access_token")) {
        //once we login we used localstorage get item thus running this conditional
        state.jwt_access_token = localStorage.getItem("access_token");
        state.isAuthenticated = true;
      } else {
        state.jwt_access_token = "";
        state.isAuthenticated = false;
      }
    },

    setisAuthenticated(state, boolean) {
      state.isAuthenticated = boolean;
    },
    setPhoneNumber(state, boolean) {
      state.isPhoneNumber = boolean;
    },
    setPreAssessment(state, pre_assessment) {
      state.preAssessmentDetails = pre_assessment;
      console.log(pre_assessment);
      localStorage.setItem("pre_assessment", JSON.stringify(pre_assessment));
    },
    setBasicDetails(state, basic_details, birthdate) {
      console.log(birthdate);
      state.basicDetails = basic_details;
      console.log(basic_details);

      localStorage.setItem("basic_details", JSON.stringify(basic_details));
    },
    updateToken(state, newToken) {
      console.log(newToken, "mga new tokens");
      localStorage.setItem("access_token", newToken.access_token);
      localStorage.setItem("refresh_token", newToken.refresh_token);

      state.jwt_access_token = newToken.access_token;
      state.jwt_refresh_token = newToken.refresh_token;
    },
    removeToken(state) {
      localStorage.removeItem("access_token");
      state.jwt_access_token = null;
    },
  },
  actions: {
    obtainToken({ commit }, payload) {
      const data = {
        recipient: payload.recipient,
        otp_code: payload.otp_code,
      };
      console.log(data);
      const obtainAccessTokenUrl = `https://admin-cas.mobile.casenote-md.com/api/v1/auth/patient_auth/register_code/?recipient_type=${payload.recipient_type}`;
      try {
        axios.post(obtainAccessTokenUrl, data).then((response) => {
          localStorage.setItem("patient_id", response.data.patient.id);
          commit("updateToken", {
            access_token: response.data.access,
            refresh_token: response.data.refresh,
          });
          commit("setisAuthenticated", true);

          console.log(response, "route_auth?????");
          router.push("/profile");
        });
      } catch (error) {
        console.log(error);
        alert(error);
      }
    },
    inspectToken() {
      const token = this.state.jwt_access_token;
      if (token) {
        const decoded = jwt_decode(token);
        const exp = decoded.exp;

        //Check if the remaining time until token expiration is less than 5 seconds
        if (exp - Date.now() / 1000 < 5) {
          console.log("Refresh the token");
          this.dispatch("refreshToken");
        } else {
          //Token is still valid, no need for immediate action
          console.log("Token is still valid");
        }
      }
    },
    refreshToken() {
      const payload = {
        refresh: this.state.jwt_refresh_token,
      };

      const obtainRefreshTokenUrl =
        "https://admin.cas.casenote-md.com/api/v1/auth/token/refresh/";

      axios
        .post(obtainRefreshTokenUrl, payload)
        .then((response) => {
          this.commit("updateToken", {
            access_token: response.data.access,
            refresh_token: response.data.refresh,
          });
          console.log(response);
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  modules: {},
});
