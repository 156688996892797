<template>
  <SideBar>
    <template #navbar><SimpleNavbar /></template>
    <template #body>
      <div class="body-container">
        <ReturningMultiStep :currentStep="3" />

        <div class="login-container col-lg-4 mx-auto">
          <div
            class="card p-3 pt-4 w-auto mb-3"
            style="
              min-height: 400px;
              border-radius: 10px;
              background: rgba(255, 255, 255, 0.6);
            "
          >
            <div class="card-body d-flex flex-column align-items-center">
              <p class="fs-4 fw-bold text-primary">Payment Successful!</p>
              <img
                src="../assets/images/checked_1.svg"
                alt=""
                class="check-img mb-3"
              />
              <hr />
              <div class="text-border fs-5 fw-bold mb-3">
                Thank you for choosing us
              </div>
              <div class="text-center">
                <div class="first-line">
                  Your conference link will be sent to your declered email
                  account. Open your link on the scheduled date. See you there!
                </div>
                <div class="second-line mb-3"></div>
              </div>
              <button class="btn btn-primary" @click="redirectToAppointment">
                Make another appointment
              </button>
            </div>
          </div>
        </div>
      </div>
    </template>
  </SideBar>
</template>

<script>
import SimpleNavbar from "../components/common/SimpleNavbar.vue";
import ReturningMultiStep from "../components/ReturningMultiStep";
import SideBar from "../components/common/SideBar";
import { useRouter } from "vue-router";

export default {
  name: "ReturningSuccessPage",

  components: {
    SimpleNavbar,
    ReturningMultiStep,
    SideBar,
  },
  setup() {
    const router = useRouter();

    const redirectToAppointment = () => {
      localStorage.removeItem("basic_details");
      localStorage.removeItem("doctor_detail");
      localStorage.removeItem("pre_assessment");

      router.push("/returning/appointment/");
    };
    return {
      redirectToAppointment,
    };
  },
};
</script>

<style scoped>
a {
  line-height: normal;
  text-decoration: none;
  list-style: none;
  color: black;
}
</style>
