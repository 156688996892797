<template>
  <div
    class="otp-container col-sm-12 col-lg-3 mx-auto pt-5 pt-lg-0"
    v-if="isPhoneCard === true"
  >
    <div
      class="card p-3 pt-4 w-auto mb-3"
      style="border-radius: 10px; background: rgba(255, 255, 255, 0.6)"
    >
      <div class="card-body">
        <form @submit.prevent="submitForm('phone')">
          <p class="text-primary fs-5 fw-bold mb-0">Welcome!</p>
          <p class="fs-6 mb-4">Enter your mobile number to continue</p>
          <div class="form-group mb-3">
            <input
              oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
              type="tel"
              v-model="phoneNumber"
              placeholder="0916XXXXXXX"
              class="form-control"
              maxlength="11"
              pattern="[0-9]*"
            />
            <div v-if="showPhoneError" class="text-danger mt-2">
              Please enter a valid Philippine phone number starting with "09"
              and followed by 9 digits.
            </div>
          </div>
          <div class="d-grid gap-2">
            <button class="btn btn-primary mt-2 mb-3">Continue</button>
          </div>
          <div class="text-center mb-sm-5">
            <a class="link-primary" @click="switchToEmailCard"
              >Log-in with email</a
            >
          </div>
        </form>
      </div>
    </div>
  </div>
  <div
    class="otp-container col-sm-12 col-lg-3 mx-auto pt-5 pt-lg-0"
    v-if="isEmailCard === true"
  >
    <div
      class="card p-3 pt-4 w-auto mb-3"
      style="border-radius: 10px; background: rgba(255, 255, 255, 0.6)"
    >
      <div class="card-body">
        <form @submit.prevent="submitForm('email')">
          <p class="text-primary fs-5 fw-bold mb-0">Welcome!</p>
          <p class="fs-6 mb-4">Enter your email to continue</p>
          <div class="form-group mb-3">
            <input
              type="email"
              v-model="email"
              placeholder="Enter email"
              class="form-control"
            />
          </div>
          <div v-if="showEmailError" class="text-danger mt-2">
            Please fill email
          </div>
          <div class="d-grid gap-2">
            <button class="btn btn-primary mt-2 mb-3">Continue</button>
          </div>
          <div class="text-center mb-sm-5">
            <a class="link-primary" @click="switchToPhoneCard"
              >Log-in with phone</a
            >
          </div>
        </form>
      </div>
    </div>
  </div>
  <div
    class="otp-container col-lg-3 col-sm-12 mx-auto pt-5 pt-lg-0"
    v-if="isOtpCard"
  >
    <div
      class="card p-3 pt-4 w-auto mb-3"
      style="border-radius: 10px; background: rgba(255, 255, 255, 0.6)"
    >
      <div class="card-body">
        <form @submit.prevent="submitRegistrationCode">
          <p class="text-primary fs-5 fw-bold mb-0">Registration Code</p>
          <p class="fs-6 mb-4">
            Enter the 6-digit registration code sent to your mobile number.
          </p>
          <div class="form-group mb-3">
            <input
              oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
              type="text"
              v-model="otp"
              placeholder="Enter 6-Digit Code"
              class="form-control"
              maxlength="6"
              pattern="[0-9]*"
            />
          </div>

          <div class="d-grid gap-2">
            <button class="btn btn-primary mt-2">Continue</button>
          </div>
        </form>
      </div>
    </div>
    <div class="text-center">
      <a href="" class="link-primary mx-auto">Didn’t get the 6-digit code?</a>
      <button
        class="btn btn-outline-primary mx-auto mt-4"
        style="display: block; min-width: 250px"
        @click="startTimer(), resendForm()"
        :disabled="timerCount > 0"
      >
        <span>Resend </span><span v-if="timerCount > 0">{{ timerCount }}</span>
      </button>
    </div>
  </div>
</template>

<script setup>
import { ref, watch, onMounted, onBeforeUnmount } from "vue";
import { useStore } from "vuex";
import axios from "axios";

import { defineProps } from "vue";

defineProps({
  next: String,
});
const phoneNumber = ref("");
const email = ref("");

const store = useStore();
const configs = store.getters.getConfigs;

const phNumberPattern = /^09\d{9}$/;
const showPhoneError = ref(false);
const showEmailError = ref(false);

const isPhoneCard = ref(true);
const isEmailCard = ref(false);
const isOtpCard = ref(false);
const switchToPhoneCard = () => {
  isPhoneCard.value = !isPhoneCard.value;
  isEmailCard.value = !isEmailCard.value;
  showPhoneError.value = false;
};
const switchToEmailCard = () => {
  isEmailCard.value = !isEmailCard.value;
  isPhoneCard.value = !isPhoneCard.value;
  showEmailError.value = false;
};
//Login
const submitForm = async (value) => {
  console.log(value);
  if (!phNumberPattern.test(phoneNumber.value) && value == "phone") {
    showPhoneError.value = true;
    store.state.isPhoneNumber = false;
    return;
  }
  if (email.value == "" && value == "email") {
    showEmailError.value = true;
    store.state.isPhoneNumber = false;

    return;
  }

  const send_value = "send";
  const phone_or_email = value;
  try {
    const response = await axios.post(
      `${configs.base_url}api/v1/auth/patient_auth/login/?recipient_type=${phone_or_email}&send_mode=${send_value}`,
      {
        recipient: phone_or_email === "email" ? email.value : phoneNumber.value,
      }
    );
    store.state.isPhoneNumber = true;
    isOtpCard.value = !isOtpCard.value;
    isEmailCard.value = false;
    isPhoneCard.value = false;
    localStorage.setItem("phone_number", phoneNumber.value);
    startTimer();
    console.log(response);
  } catch (error) {
    console.log(error);
    alert(error);
  }
};

const resendForm = async () => {
  const send_value = "resend";
  let phone_or_email = "";

  if (email.value) {
    console.log(email.value);
    phone_or_email = "email";
  }
  if (phoneNumber.value) {
    console.log(phoneNumber.value);
    phone_or_email = "phone";
  }
  try {
    const response = await axios.post(
      `${configs.base_url}api/v1/auth/patient_auth/login/?recipient_type=${phone_or_email}&send_mode=${send_value}`,
      {
        recipient: phone_or_email === "email" ? email.value : phoneNumber.value,
      }
    );
    console.log(response);
  } catch (error) {
    console.log(error);
    alert(error);
  }
};

watch(phoneNumber, (newValue, oldValue) => {
  if (newValue !== oldValue) {
    phoneNumber.value = newValue.replace(/\D/g, "");
  }
});

//OTP VIEW

const otp = ref("");

const submitRegistrationCode = async () => {
  let phone_or_email = "";

  if (email.value) {
    console.log(email.value);
    phone_or_email = "email";
  }
  if (phoneNumber.value) {
    console.log(phoneNumber.value);
    phone_or_email = "phone";
  }
  let payload = {
    recipient_type: phone_or_email === "email" ? "email" : "phone",
    recipient: phone_or_email === "email" ? email.value : phoneNumber.value,
    otp_code: otp.value,
  };
  store.dispatch("obtainToken", payload);
};

watch(otp, (newValue, oldValue) => {
  if (newValue !== oldValue) {
    otp.value = newValue.replace(/\D/g, "");
  }
});

const timerCount = ref(0);
const startTime = ref(null);
const initialTimerValue = 30;

//Load timerCount and startTime from localStorage on component mount
const loadFromLocalStorage = () => {
  const storedTimerCount = localStorage.getItem("timerCount");
  if (storedTimerCount !== null) {
    timerCount.value = parseInt(storedTimerCount);
  }

  const storedStartTime = localStorage.getItem("startTime");
  if (storedStartTime !== null) {
    startTime.value = parseInt(storedStartTime);
    //Calculate elapsed time and adjust the timerCount
    const currentTime = Date.now();
    const elapsedTime = Math.floor((currentTime - startTime.value) / 1000);
    if (elapsedTime < initialTimerValue) {
      timerCount.value = initialTimerValue - elapsedTime;
    } else {
      timerCount.value = 0;
    }
    //Start the countdown again
    startCountdown();
  }
};

//Save timerCount and startTime to localStorage whenever they change
watch(timerCount, (newValue) => {
  localStorage.setItem("timerCount", newValue.toString());
});

watch(startTime, (newValue) => {
  localStorage.setItem("startTime", newValue.toString());
});

//Trigger the loading function when the component is mounted
onMounted(loadFromLocalStorage);

//Start the countdown
const startCountdown = () => {
  if (timerCount.value > 0) {
    const interval = setInterval(() => {
      timerCount.value--;
      if (timerCount.value === 0) {
        clearInterval(interval);
      }
    }, 1000);
  }
};

const startTimer = () => {
  if (timerCount.value === 0) {
    startTime.value = Date.now();
    timerCount.value = initialTimerValue;
    startCountdown();
  }
};

//Save the timer state before the page is unloaded
const saveStateBeforeUnload = () => {
  localStorage.setItem("timerCount", timerCount.value.toString());
  localStorage.setItem("startTime", startTime.value.toString());
};

//Register the beforeunload event listener
onBeforeUnmount(saveStateBeforeUnload);
</script>

<style scoped>
.main-container {
  background-image: url("../../assets/images/Mobile_BG.png");
  min-height: 800px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

a {
  line-height: normal;
  text-decoration: none;
  list-style: none;
  color: black;
}

@media (min-width: 576px) {
  .main-container {
    background-image: url("../../assets/images/Mobile_BG.png");
    min-height: 800px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
}

@media (min-width: 992px) {
  .main-container {
    background-image: url("../../assets/images/BG.jpg");
    min-height: 800px;

    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .otp-container {
    position: absolute;
    top: 30%;
    left: 10%;
  }
}
</style>
