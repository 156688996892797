<template>
  <SideBar>
    <template #navbar><SimpleNavbar /></template>

    <template #body>
      <div class="bg-color">
        <ReturningMultiStep :currentStep="1" />

        <div class="container">
          <div class="row justify-content-center">
            <div class="col-lg-8">
              <div class="main-card card w-auto">
                <div class="card-body">
                  <form>
                    <div class="row">
                      <div class="mb-3">
                        <label for="name">Primary Symptoms</label>
                        <input
                          type="text"
                          class="form-control"
                          v-model="formData.primary_symptoms"
                        />
                      </div>
                      <div class="mb-3">
                        <label
                          >Current Medical Condition Being Treated/Past Medical
                          Condition</label
                        >
                        <input
                          type="text"
                          class="form-control"
                          v-model="formData.current_medical_condition"
                        />
                      </div>
                      <div class="mb-3">
                        <label for="name">Allergies </label>
                        <input
                          type="text"
                          class="form-control"
                          v-model="formData.allergies"
                        />
                      </div>
                      <div class="mb-3 col-8">
                        <label
                          >How often do you have a drink containing
                          alcohol?</label
                        >
                        <select
                          class="form-control"
                          v-model="formData.alcohol_frequency"
                        >
                          <option :value="null" disabled selected>
                            Select
                          </option>
                          <option>4 or more times a week</option>
                        </select>
                      </div>
                      <div class="mb-3 col-4">
                        <label for="">Are you a smoker</label>
                        <div>
                          <label class="me-2">
                            <input
                              type="radio"
                              name="choice-radio"
                              v-model="formData.is_smoker"
                              value="True"
                            />
                            Yes
                          </label>
                          <label>
                            <input
                              type="radio"
                              name="choice-radio"
                              v-model="formData.is_smoker"
                              value="False"
                            />
                            No
                          </label>
                        </div>
                      </div>
                      <div class="mb-3">
                        <label>Current medications</label>
                        <input
                          type="text"
                          class="form-control"
                          v-model="formData.current_medication"
                        />
                      </div>
                      <div class="d-flex">
                        <BackBtnItem :next="'appointment/'" class="me-2" />
                        <button
                          type="button"
                          class="btn btn-primary"
                          @click="storeFormDataInLocalStorage"
                        >
                          Next
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </SideBar>
</template>

<script>
import { ref } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";

import BackBtnItem from "../components/common/BackBtnItem.vue";
import SimpleNavbar from "../components/common/SimpleNavbar.vue";
import SideBar from "../components/common/SideBar";
import ReturningMultiStep from "../components/ReturningMultiStep";

export default {
  name: "MultistepForm",
  components: {
    BackBtnItem,
    SimpleNavbar,
    SideBar,
    ReturningMultiStep,
  },
  setup() {
    const formData = ref({
      current_medical_condition: "",

      primary_symptoms: "",
      allergies: "",
      alcohol_frequency: "0",
      current_medication: "",
      is_smoker: "",
    });
    const store = useStore();
    const next = ref("");
    const router = useRouter();
    const storeFormDataInLocalStorage = () => {
      if (
        formData.value.primary_symptoms &&
        formData.value.current_medical_condition &&
        formData.value.allergies &&
        formData.value.alcohol_frequency &&
        formData.value.current_medication &&
        formData.value.is_smoker
      ) {
        store.commit("setPreAssessment", formData.value);
        router.push("/returning/payment-summary/");
      } else {
        console.log(formData.value);
        alert("Please fill in all required fields.");
      }
    };
    const getPreAssessmentLocalStorage = () => {
      const data = localStorage.getItem("pre_assessment");
      if (data) {
        const parsedData = JSON.parse(data);
        formData.value = { ...formData.value, ...parsedData };
      }
    };
    getPreAssessmentLocalStorage();

    return {
      storeFormDataInLocalStorage,
      formData,
      next,
      store,
    };
  },
};
</script>

<style scoped>
.container {
  margin-top: 50px;
}

.container-date {
  display: block;
  text-align: center;
}

.main-card {
  position: relative;
}

input[type="text"],
textarea {
  background-color: #d1d1d1;
}

input[type="email"],
textarea {
  background-color: #d1d1d1;
}

select {
  background-color: #d1d1d1;
}

select option {
  background-color: #d1d1d1;
}
</style>
