<template>
  <div :class="{ backdrop: isActiveAppointmentCard }"></div>

  <SideBar class="">
    <template #navbar>
      <SimpleNavbar class="" />
    </template>
    <template #body>
      <div class="container-fluid">
        <div
          class="card except col-6"
          v-show="isActiveAppointmentCard === true"
          v-click-outside="onClickOutside"
        >
          <div class="card-body p-0">
            <div
              class="nav d-block"
              style="background-color: rgba(23, 121, 201, 1); height: 50px"
            ></div>
            <div class="p-5">
              <p class="fs-3 fw-bold">Consultation</p>
              <div class="date">
                <p class="fs-6">
                  Date:
                  {{
                    patientsSingleMDR.appointment_date || "No data available"
                  }}
                </p>
              </div>
              <div class="p-3">
                <p class="fs-6 fw-bold">Primary Symptoms:</p>
                <div
                  v-for="(
                    primary_symptom, index
                  ) in patientsSingleMDR.preassessment"
                  :key="index"
                >
                  <p class="fs-6">
                    {{
                      primary_symptom.primary_symptoms || "No data available"
                    }}
                  </p>
                </div>
                <p class="fs-6 fw-bold">
                  Current Medical Condition Being Treated/Past Medical
                  Condition:
                </p>
                <div
                  v-for="(preass, index) in patientsSingleMDR.preassessment"
                  :key="index"
                >
                  <p class="fs-6">{{ preass.current_medical_condition }}</p>
                  <p class="fs-6 fw-bold">Allergies (Food/Medications):</p>

                  <p class="fs-6">{{ preass.allergies }}</p>
                  <div class="d-flex justify-content-between">
                    <div>
                      <p class="fs-6 fw-bold">
                        How often do you have a drink containing alcohol?
                      </p>
                      <p class="fs-6">{{ preass.alcohol_frequency }}</p>
                    </div>
                    <div>
                      <p class="fs-6 fw-bold">Are you a smoker?</p>
                      <p class="fs-6">
                        {{ preass.is_smoker === false ? "No" : "Yes" }}
                      </p>
                    </div>
                  </div>
                  <p class="fs-6 fw-bold">Current medications</p>
                  <p class="fs-6">{{ preass.current_medication }}</p>
                </div>
                <p class="fs-6 fw-bold">Diagnosis:</p>

                <div
                  v-for="(diagnose, index) in patientsSingleMDR.diagnosis"
                  :key="index"
                >
                  <p class="fs-6">
                    {{ diagnose || "No data is available" }}
                  </p>
                </div>
                <p class="fs-6 fw-bold">Laboratory Test Request:</p>
                <div
                  v-for="(lab, index) in patientsSingleMDR.laboratory_test"
                  :key="index"
                >
                  <p class="fs-6 fw-bold mb-0">
                    {{ lab.laboratory_type || "No file" }}
                  </p>
                </div>
                <div class="input-group mb-3">
                  <label class="input-group-text" @click="startUpload"
                    >Upload</label
                  >
                  <input
                    type="file"
                    class="form-control"
                    id="inputGroupFile01"
                    ref="fileInput"
                  />
                </div>
                <p class="fs-6 fw-bold">Attached Files:</p>
                <p>UrineTest.jpeg</p>
                <p>UrineTest.jpeg</p>
                <p>UrineTest.jpeg</p>
              </div>

              <div class="buttons d-flex justify-content-end">
                <button class="btn btn-primary me-3 fw-bold">
                  Upload file
                </button>
                <button class="btn btn-primary fw-bold" @click="closeButton">
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="table-responsive mt-5">
          <table class="table table-bordered text-center">
            <thead class="border">
              <tr class="table-row">
                <th scope="col">Full Name</th>
                <th scope="col">Age</th>
                <th class="text-center" scope="col" colspan="4">
                  Contact Details
                </th>
                <th scope="col" colspan="2">Weight and Height</th>
              </tr>
            </thead>
            <tbody class="border table-borderless">
              <tr class="table-row">
                <td>{{ profileDetails.full_name }}</td>
                <td>44</td>
                <td>
                  <span class="fw-bold">Mobile Number: </span
                  ><span>{{ profileDetails.mobile_number }}</span>
                </td>
                <td>
                  <span class="fw-bold">Email Address: </span
                  ><span>{{ profileDetails.email }}</span>
                </td>
                <td>
                  <span class="fw-bold">Tel Number: </span><span>NA</span>
                </td>
                <td>
                  <span class="fw-bold">Address: </span
                  ><span>{{ profileDetails.address }}</span>
                </td>
                <td>
                  <span class="fw-bold">Weight: </span
                  ><span>{{ profileDetails.weight }} Kg</span>
                </td>
                <td>
                  <span class="fw-bold">Height </span
                  ><span>{{ profileDetails.height }}</span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="buttons-container d-flex align-items-center mb-3">
          <button
            class="button-date btn btn-info me-3 fw-bold"
            @click="startDateButton"
          >
            <i class="bi bi-calendar-week"></i> Start Date
          </button>
          <p class="my-auto me-3 fw-bold">to</p>
          <button
            class="button-date btn btn-info me-3 fw-bold"
            @click="endDateButton"
          >
            <i class="bi bi-calendar-week"></i>
            End Date
          </button>
          <div class="input-group me-auto w-25">
            <div class="input-group-text">
              <i class="bi bi-search" @click="searchBar"></i>
            </div>
            <input
              type="text"
              class="form-control p-2"
              aria-label="Text input with checkbox"
              v-model="searchData"
            />
          </div>
          <div class="d-flex align-items-center">
            <div>
              <i
                class="arrow fs-4 bi bi-arrow-left-short"
                @click="backButtonList"
              ></i>
            </div>
            <div>
              <i
                class="arrow fs-4 bi bi-arrow-right-short"
                @click="nextButtonList"
              ></i>
            </div>
            <div>{{ paginationInfo }}</div>
          </div>
        </div>
        <div class="table-responsive">
          <table class="table table-bordered text-center">
            <thead class="border table-borderless">
              <tr class="table-row">
                <th scope="col">Date of Consultation</th>
                <th scope="col">Pre-Assessment</th>
                <th class="" scope="col">Diagnosis</th>
                <th scope="col">Medication</th>
              </tr>
            </thead>
            <tbody class="border table-borderless">
              <tr
                class="table-row"
                v-for="patientAppointment in patientsMDR"
                :key="patientAppointment.id"
                @click="toggleAppointmentRow($event, patientAppointment)"
              >
                <td>{{ patientAppointment.appointment_date }}</td>
                <td>
                  <span class="fw-bold">Primary Symptoms: </span>
                  <template v-if="patientAppointment.preassessment.length > 0">
                    <p
                      v-for="primary_symptom in patientAppointment.preassessment"
                      :key="primary_symptom.id"
                    >
                      {{ primary_symptom.primary_symptoms }}
                    </p>
                  </template>
                  <p v-else>No preassessment data available</p>
                </td>
                <td>
                  <template v-if="patientAppointment.diagnosis.length > 0">
                    <p
                      v-for="diagnose in patientAppointment.diagnosis"
                      :key="diagnose.id"
                    >
                      {{ diagnose }}
                    </p>
                  </template>
                  <p v-else>No diagnosis data available</p>
                </td>
                <td>
                  <template v-if="patientAppointment.medication.length > 0">
                    <p
                      v-for="medication in patientAppointment.medication"
                      :key="medication.id"
                    >
                      {{ medication.medicine }}
                    </p>
                  </template>
                  <p v-else>No medication data available</p>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </template>
  </SideBar>
</template>

<script setup>
import SideBar from "../components/common/SideBar.vue";
import SimpleNavbar from "../components/common/SimpleNavbar.vue";

import { ref, onMounted, computed } from "vue";
import { useStore } from "vuex";
import axios from "axios";

const profileDetails = ref({
  full_name: "",
  age: "",
  mobile_number: "",
  email: "",
  tel_number: "",
  address: "",
  weight: "",
  height: "",
});

const searchData = ref("");
const patientsMDR = ref([]);
const patientsSingleMDR = ref({});
const patientsSingeMDRForLab = ref({
  doctor_id: "",
  appointment_id: "",
});
const token = localStorage.getItem("access_token");
const store = useStore();
const patient_id = localStorage.getItem("patient_id");

const currentPage = ref(1);
const pageSize = 1;
const totalPages = ref(0);
onMounted(async () => {
  const configs = store.getters.getConfigs;

  const headers = {
    Authorization: `Bearer ${token}`,
  };

  try {
    const response = await axios.get(
      `${configs.base_url}api/v1/patient_profile/detail/${patient_id}`
    );
    const modify_number = response.data.phone_number.replace("+63", "0");

    profileDetails.value.full_name = `${response.data.first_name} ${response.data.middle_name} ${response.data.last_name}`;
    profileDetails.value.email = response.data.email;
    profileDetails.value.mobile_number = modify_number;
    profileDetails.value.age = response.data.age;
    profileDetails.value.address = response.data.address;
    profileDetails.value.weight = response.data.weight;
    profileDetails.value.height = response.data.height;

    try {
      const response = await axios.get(
        `${configs.base_url}api/v1/appointment/my-mdr-list/?page_size=${pageSize}&page=${currentPage.value}`,
        { headers }
      );
      console.log(response.data);
      patientsMDR.value = response.data.results;
      totalPages.value = response.data.count;
    } catch (error) {
      console.error(error);
    }
  } catch (error) {
    console.error(error);
  }
});

//For toggling appointment rows
const isActiveAppointmentCard = ref(false);
const configs = store.getters.getConfigs;

const toggleAppointmentRow = async (event, patientAppointment) => {
  event.stopPropagation();
  isActiveAppointmentCard.value = !isActiveAppointmentCard.value;

  const headers = {
    Authorization: `Bearer ${token}`,
  };
  try {
    const response = await axios(
      `${configs.base_url}api/v1/appointment/my-mdr-details/${patientAppointment.id}/`,
      { headers }
    );
    console.log(response.data);
    patientsSingleMDR.value = response.data;
    patientsSingeMDRForLab.value.appointment_id = response.data.id;
    patientsSingeMDRForLab.value.doctor_id = response.data.doctor;
  } catch (error) {
    console.log(error);
  }

  console.log(patientAppointment);
};

const onClickOutside = () => {
  isActiveAppointmentCard.value = false;
  console.log();
};

const closeButton = () => {
  isActiveAppointmentCard.value = false;
};

const startDateButton = () => {
  console.log();
};

const endDateButton = () => {
  console.log();
};

const searchBar = async () => {
  const headers = {
    Authorization: `Bearer ${token}`,
  };
  try {
    const response = await axios.get(
      `${configs.base_url}api/v1/appointment/my-mdr-list/?search=${searchData.value}`,
      { headers }
    );
    console.log(response);
    patientsMDR.value = response.data.results;
  } catch (error) {
    console.log(error);
  }
  console.log();
};

const getAppointments = async () => {
  const headers = {
    Authorization: `Bearer ${token}`,
  };
  try {
    const response = await axios.get(
      `${configs.base_url}api/v1/appointment/my-mdr-list/?page_size=${pageSize}&page=${currentPage.value}`,
      { headers }
    );
    console.log(response.data);
    patientsMDR.value = response.data.results;
    totalPages.value = response.data.count;
    // Process the second response data here...
  } catch (error) {
    console.error(error);
  }
};

// Previous button click handler
const backButtonList = () => {
  if (currentPage.value > 1) {
    currentPage.value--;
    getAppointments();
  }
};

// Next button click handler
const nextButtonList = () => {
  console.log(currentPage);
  if (currentPage.value < totalPages.value) {
    currentPage.value++;
    getAppointments();
  }
};

const fileInput = ref(null);
const startUpload = () => {
  if (fileInput.value && fileInput.value.files.length > 0) {
    const file = fileInput.value.files[0];
    uploadFile(file);
  } else {
    console.error("No file selected.");
  }
};

const uploadFile = async (file) => {
  try {
    const formData = new FormData();
    formData.append("laboratory_type", "Blood test");
    formData.append("patient_id", "");
    formData.append("doctor_id", patientsSingeMDRForLab.value.doctor_id);
    formData.append(
      "appointment_id",
      patientsSingeMDRForLab.value.appointment_id
    );
    formData.append("laboratory_file", file);

    const response = await axios.post(
      `${configs.base_url}api/v1/laboratory_test/create/`,
      formData
    );
    console.log(response);
  } catch (error) {
    console.error("Error uploading file:", error);
  }
};

const paginationInfo = computed(() => {
  return `${(currentPage.value - 1) * pageSize + 1}-${Math.min(
    currentPage.value * pageSize,
    totalPages.value
  )} of ${totalPages.value * pageSize}`;
});
</script>

<style scoped>
.button-date {
  width: 150px;
}

.input-group-text:hover {
  background-color: #fccd40;
}

.input-group-text:active {
  color: #1779c9;
}

.arrow {
  cursor: pointer;
}
.arrow:hover {
  color: #1779c9;
}
.arrow:active {
  color: black;
}

.table-row > th {
  background-color: rgba(23, 121, 201, 1);
  color: rgba(255, 255, 255, 1);
  border: none;
}

.btn-info {
  border: 1px solid rgba(196, 196, 196, 0.5);
  border-radius: 16px;
  background-color: rgba(255, 255, 255, 1);
}

.btn-info:hover {
  border: 1px solid rgba(196, 196, 196, 0.5);
  border-radius: 16px;
  background-color: #fccd40;
}

.buttons > button {
  width: 150px;
}

.card {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 100;
  height: 90%;
  overflow: scroll;
  overflow-x: hidden;
}

.except {
  /* position: relative; */
  z-index: 9999;
}

.backdrop {
  position: fixed;
  z-index: 9998;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: #33333377;
  backdrop-filter: blur(4px);
}
</style>
