import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

// import "bootstrap/dist/css/bootstrap.min.css";
// import "bootstrap";

import { VueReCaptcha } from "vue-recaptcha-v3";
import vClickOutside from "click-outside-vue3";
import StepProgress from "vue-step-progress";
import "vue-step-progress/dist/main.css";

import "@/scss/custom.scss";

createApp(App)
  .use(store)
  .use(router)
  .use(vClickOutside)
  .use(StepProgress)
  .use(VueReCaptcha, { siteKey: "6Lcqet8mAAAAAPt0ud9tM8U7rMnWnhxc2q4y2GTw" })
  .mount("#app");
