<template>
  <div :class="{ backdrop: isActiveDoctorCard }"></div>
  <SimpleNavbar />
  <div class="bg-color">
    <MultiStep :currentStep="0" />
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-8">
          <div
            class="active-doctor-card except"
            v-show="isActiveDoctorCard === true"
            v-click-outside="onClickOutside"
          >
            <div class="d-flex row p-3">
              <div class="d-flex flex-column align-items-center text-info">
                <img
                  class="doctor-image"
                  src="../assets/images/jenny.jpg"
                  alt=""
                />
                <p>{{ selectedDoctorInfo.doctor_name }}</p>
                <p>{{ selectedDoctorInfo.fee }}</p>
              </div>
              <div
                v-for="(schedule, index) in selectedDoctor"
                v-bind:key="schedule"
                class="col-md-6"
              >
                <div class="">
                  <button
                    class="time-button btn btn-sm fw-bold mb-2"
                    :style="changeBtnColor(index)"
                    @click="setActive(schedule, index)"
                  >
                    {{ schedule }}
                  </button>
                </div>
              </div>
              <div class="d-flex justify-content-end mt-3">
                <button
                  class="select-button btn btn-sm fw-bold"
                  @click="submitSchedule"
                >
                  Select
                </button>
              </div>
            </div>
          </div>
          <div>
            <div class="d-flex justify-content-between">
              <div class="container-date me-5">
                <DatePicker
                  :min-date="new Date()"
                  @dayclick="dateClick"
                  @click="selectSpecialization"
                  class=""
                  v-model="formData.date"
                  expanded
                />
              </div>
              <div class="container-doctors card p-3">
                <p class="fs-3 fw-bold" style="color: #4c4947">
                  Available Doctors
                </p>
                <select
                  class="dropdown mb-3"
                  v-model="specializationSelected"
                  @change="selectSpecialization"
                >
                  <option :value="null" disabled selected>
                    Specialization
                  </option>
                  <option
                    v-for="special in specializationList"
                    :key="special.id"
                    :value="special.specialization"
                  >
                    {{ special.specialization }}
                  </option>
                </select>
                <div class="scrollable list overflow-y-auto overflow-x-hidden">
                  <div
                    class="container-doctor-lst d-flex flex-wrap justify-content-between row mb-3"
                  >
                    <div
                      v-for="doctor in availableDoctors"
                      :key="doctor.id"
                      class="card doctor-card col-md-6 mb-2 rounded-0"
                      @click="toggleDoctorCard($event, doctor)"
                    >
                      <img
                        class="doctor-image"
                        src="../assets/images/jenny.jpg"
                        alt=""
                      />
                      <p class="doctor-name fs-6">
                        {{ doctor.doctor_name }}
                      </p>
                      <p class="fs-6">{{ doctor.specialization }}</p>
                      <p class="fs-6">
                        {{ doctor.fee }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from "vue";
import { DatePicker } from "v-calendar";
import "v-calendar/style.css";
import axios from "axios";
import { useRouter } from "vue-router";
import { useStore } from "vuex";

import SimpleNavbar from "../components/common/SimpleNavbar";
import MultiStep from "../components/MultiStep";

export default {
  name: "MultistepForm",

  components: {
    DatePicker,
    SimpleNavbar,
    MultiStep,
  },

  setup() {
    const router = useRouter();
    const store = useStore();
    const configs = store.getters.getConfigs;

    const availableDoctors = ref([]);
    const isActive1 = ref(false);
    const isActive2 = ref(false);

    const isActiveDoctorCard = ref(false);
    const selectedDoctor = ref([]);
    const selectedDoctorInfo = ref({
      doctor_name: "",
      fee: "",
      image: "",
    });
    const selectedOption = ref(null);
    const active = ref();

    //For dropdown
    const specializationSelected = ref(null);
    const specializationList = ref([]);

    const dateData = ref({
      weekday: "",
      date: "",
    });
    const formData = ref({
      schedule: "",
    });

    const doctorData = ref({
      doctor: "",
      appointment_date: "",
      appointment_timeslot: "",
      fee: "",
    });
    const toggle = () => {
      isActive1.value = !isActive1.value;
    };

    const toggle2 = () => {
      isActive2.value = !isActive2.value;
    };

    const setActive = (schedule, index) => {
      active.value = index;
      formData.value.schedule = schedule;
      doctorData.value.appointment_timeslot = schedule;
    };

    const changeBtnColor = (index) => {
      return {
        backgroundColor:
          active.value === index
            ? "rgba(252, 205, 64, 1)"
            : "rgba(255, 255, 255, 1)",
      };
    };

    const toggleDoctorCard = (event, doctor) => {
      event.stopPropagation();
      isActiveDoctorCard.value = !isActiveDoctorCard.value;
      console.log(doctor);
      selectedDoctor.value = doctor.time_slots;
      selectedDoctorInfo.value = doctor;
      doctorData.value.fee = doctor.fee;
      doctorData.value.doctor = doctor.id;

      // try {
      //   const response = await axios.get(

      //   )
      // }
    };

    // const storeFormDataInLocalStorage = () => {
    //   const formDataJson = JSON.stringify(formData.value);
    //   localStorage.setItem("formData", formDataJson);
    // };

    const onClickOutside = () => {
      isActiveDoctorCard.value = false;
      console.log();
    };

    const dateClick = async (day) => {
      console.log(day);
      dateData.value.weekday = day.weekday;
      dateData.value.date = day.id;
      doctorData.value.appointment_date = day.id;
    };

    const selectSchedule = (schedule) => {
      formData.value.schedule = schedule;
      console.log(schedule);
    };

    const submitSchedule = () => {
      localStorage.setItem("doctor_detail", JSON.stringify(doctorData.value));

      router.push("/pre-assessment");
    };

    const selectSpecialization = async () => {
      const specialization = specializationSelected.value;
      const weekday = dateData.value.weekday;

      if (!weekday) {
        // Do nothing if weekday is null
        return;
      }
      if (!specialization) {
        // Do nothing if weekday is null
        return;
      }
      try {
        const response = await axios.get(
          `${configs.base_url}api/v1/doctor_profile/available_doctors/?weekday_key=${weekday}&specialization_key=${specialization}`
        );
        availableDoctors.value = response.data;
        console.log(availableDoctors.value);
      } catch (error) {
        console.error(error);
      }
    };
    //test
    onMounted(async () => {
      try {
        const response = await axios.get(
          `${configs.base_url}api/v1/specialty/list/`
        );
        specializationList.value = response.data;
        console.log(response);
        console.log(specializationList.value);
      } catch (error) {
        console.error(error);
      }
    });

    return {
      selectedOption,
      availableDoctors,
      dateClick,
      //toggles
      isActive1,
      isActive2,
      isActiveDoctorCard,
      toggleDoctorCard,
      onClickOutside,
      selectedDoctor,
      selectSchedule,
      toggle,
      toggle2,
      formData,
      specializationSelected,
      specializationList,
      selectSpecialization,
      setActive,
      changeBtnColor,
      active,
      submitSchedule,
      selectedDoctorInfo,
      doctorData,
    };
  },
};
</script>

<style scoped>
.bg {
  background-color: rgba(248, 249, 251, 1);
}

.container-date {
  display: block;
  text-align: center;
  width: 50%;
}

.main-card {
  position: relative;
}

input[type="text"],
textarea {
  background-color: #d1d1d1;
}

.dropdown {
  height: 40px;
  background: #f2f2f2;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: bold;
  border: none;
  outline: none;
  scroll-behavior: smooth;
}

.dropdown > p {
  padding-left: 10px;
  padding-top: 15px;
}

.container-doctors {
  width: 40%;
  height: 600px;
}

.container-doctor-lst > div {
  width: 45%;
  min-width: 150px;
  height: 200px;
  background-repeat: no-repeat;
  background-color: rgba(23, 121, 201, 1);
  display: flex;
  align-items: center;
  justify-content: center;
}

.active-doctor-card {
  width: 350px;
  min-height: 200px;
  background-color: rgba(23, 121, 201, 1);
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 100;
  border-radius: 5%;
}

.doctor-card > p {
  color: white;
}

.scrollable-list {
}

.doctor-image {
  width: 61px;
  height: 61px;
  flex-shrink: 0;
}

.doctor-name {
  font-weight: bold;
}

.except {
  /* position: relative; */
  z-index: 9999;
}

.select-button {
  background-color: white;
}

.select-button:hover {
  background-color: rgba(252, 205, 64, 1);
}

.backdrop {
  position: fixed;
  z-index: 9998;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: #33333377;
  backdrop-filter: blur(4px);
}

.time-button {
  min-width: 150px;
}

.circle {
  border-radius: 50%;
  width: 34px;
  height: 34px;
  padding: 4px;
  background: #fff;
  border: 3px solid #799dba;
  color: #799dba;
  text-align: center;
}
</style>
