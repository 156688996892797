import { createRouter, createWebHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";
import AppointmentDetailsView from "../views/AppointmentDetailsView.vue";
import PreAssessmentView from "../views/PreAssessmentView.vue";
import BasicDetailsView from "../views/BasicDetailsView.vue";
import SuccessPage from "../views/SuccessPage.vue";
import VideoCallLauncherView from "../views/VideoCallLauncherView.vue";
import OTPView from "../views/OTPView.vue";
import PaymentSummaryView from "../views/PaymentSummaryView.vue";

//Returning

import SideBarTestPage from "../views/SideBarTestPage.vue";
import PatientProfile from "../views/PatientProfileView.vue";
import MDRView from "../views/MDRView.vue";
import ReturningPatientsLandingView from "../views/ReturningPatientsLandingView.vue";
import ReturningAppointmentDetailsView from "../views/ReturningAppointmentDetailsView.vue";
import ReturningPreAssessmentView from "../views/ReturningPreAssessmentView.vue";
import ReturningPaymentSummaryView from "../views/ReturningPaymentSummaryView.vue";
import ReturningSuccessPageView from "../views/ReturningSuccessPageView.vue";
import ReturningConferenceView from "../views/ReturningConferenceView.vue";

import EPrescriptionPageView from "../views/EPrescriptionPageView.vue";

import { useStore } from "vuex";

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },

  {
    path: "/appointment",
    name: "appointment",
    component: AppointmentDetailsView,
  },
  {
    path: "/pre-assessment",
    name: "pre-assessment",
    component: PreAssessmentView,
  },
  {
    path: "/basic-details",
    name: "BasicDetailsView",
    component: BasicDetailsView,
  },
  {
    path: "/payment-summary",
    name: "payment-summary",
    component: PaymentSummaryView,
  },
  {
    path: "/success",
    name: "success",
    component: SuccessPage,
  },
  {
    path: "/video-call",
    name: "video-call",
    component: VideoCallLauncherView,
  },
  {
    path: "/returning",
    name: "returning",
    component: ReturningPatientsLandingView,
  },
  {
    path: "/otp",
    name: "otp",
    component: OTPView,
    meta: {
      // requirePhoneNumber: true,
    },
  },
  {
    path: "/sidebar/",
    name: "sidebar",
    component: SideBarTestPage,
  },
  {
    path: "/profile/",
    name: "profile",
    component: PatientProfile,
    meta: {
      isAuthenticated: true,
    },
  },
  {
    path: "/mdr/",
    name: "mdr",
    component: MDRView,
    meta: {
      isAuthenticated: true,
    },
  },
  {
    path: "/returning/appointment/",
    name: "returning-appointment",
    component: ReturningAppointmentDetailsView,
    meta: {
      isAuthenticated: true,
    },
  },
  {
    path: "/returning/pre-assessment/",
    name: "returning-pre-assessment",
    component: ReturningPreAssessmentView,
    meta: {
      isAuthenticated: true,
    },
  },
  {
    path: "/returning/payment-summary/",
    name: "returning-payment-summary",
    component: ReturningPaymentSummaryView,
    meta: {
      isAuthenticated: true,
    },
  },
  {
    path: "/returning/success/",
    name: "returning-success",
    component: ReturningSuccessPageView,
    meta: {
      isAuthenticated: true,
    },
  },
  {
    path: "/returning/e-prescription/",
    name: "returning-e-prescription",
    component: EPrescriptionPageView,
    meta: {
      isAuthenticated: true,
    },
  },
  {
    path: "/returning/conference/",
    name: "returning-conference",
    component: ReturningConferenceView,
    meta: {
      isAuthenticated: true,
    },
  },
];

const router = createRouter({
  history: createWebHistory("/"),
  routes,
});

router.beforeEach((to, from, next) => {
  const store = useStore();

  if (
    to.matched.some((record) => record.meta.isAuthenticated) &&
    !store.state.isAuthenticated
  ) {
    next("/returning");
  } else {
    next();
  }
});

export default router;
