<template>
  <SideBar>
    <template #navbar><SimpleNavbarVue /></template>
    <template #body>
      <div :class="{ backdrop: isSubmitContact }"></div>
      <div
        class="otp-card except"
        v-show="isSubmitContact === true"
        v-click-outside="onClickOutside"
      >
        <div class="card w-75 mx-auto">
          <div class="card-body text-center p-5">
            <p class="fs-5 text-primary fw-bold">Registration Code</p>
            <p class="fs-6">
              Enter the 6-digit registration code sent to your mobile number
            </p>

            <input
              oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
              type="text"
              class="form-control mb-3"
              placeholder="Enter 6 Digit Code"
              maxlength="6"
              pattern="[0-9]*"
              v-model="otp"
            />
            <div class="d-grid gap-2">
              <button class="btn btn-primary mb-3">Continue</button>
            </div>
            <p class="fs-6">Didn't get the 6-digit code?</p>
            <div class="d-grid gap-2">
              <button
                class="btn btn-outline-primary"
                @click="handleResendOTP"
                :disabled="isCooldown"
              >
                {{ buttonText }}
              </button>
            </div>
          </div>
        </div>
      </div>

      <div class="container mt-5">
        <div class="card">
          <div class="card-body">
            <div>
              <p class="fs-4">Edit Profile</p>
              <p class="fs-5 text-primary fw-bold">Basic Details</p>
            </div>
            <div>
              <div class="row mb-3">
                <div class="col-lg-3">
                  <label for="name">Last Name</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="profileDetails.lastName"
                  />
                  <p class="text-danger" v-if="validationErrors.lastName">
                    Please enter this field
                  </p>
                </div>
                <div class="col-lg-3">
                  <label for="name">First Name</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="profileDetails.firstName"
                  />
                </div>
                <div class="col-lg-3">
                  <label for="name">Middle Name</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="profileDetails.middleName"
                  />
                </div>
              </div>
              <div class="mb-3">
                <div class="row">
                  <div class="col-lg-6">
                    <label class="form-label">Date of Birth</label>
                    <div class="d-flex">
                      <div class="flex-fill me-1">
                        <select
                          id="yearSelect"
                          @change="updateDays"
                          class="form-select"
                          v-model="profileDetails.birthYear"
                        >
                          <option value="" disabled>Select Year</option>
                          <option
                            v-for="year in years"
                            :key="year"
                            :value="year"
                          >
                            {{ year }}
                          </option>
                        </select>
                      </div>
                      <div class="flex-fill me-1">
                        <select
                          id="monthSelect"
                          @change="updateDays"
                          class="form-select"
                          v-model="profileDetails.birthMonth"
                        >
                          <option value="" disabled>Select Month</option>
                          <option
                            v-for="(month, index) in months"
                            :key="index"
                            :value="index + 1"
                          >
                            {{ month }}
                          </option>
                        </select>
                      </div>
                      <div class="flex-fill me-1">
                        <select
                          id="daySelect"
                          class="form-select"
                          v-model="profileDetails.birthDay"
                        >
                          <option value="" disabled>Select Day</option>
                          <option
                            v-for="day in daysInMonth"
                            :key="day"
                            :value="day"
                          >
                            {{ day }}
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-1">
                    <label for="name" class="mb-2">Age</label>
                    <input
                      type="text"
                      class="form-control"
                      v-model="profileDetails.age"
                    />
                  </div>
                  <div class="col-lg-3">
                    <label for="" class="mb-3">Gender</label>
                    <div>
                      <label class="me-2">
                        <input
                          type="radio"
                          name="choice-radio"
                          value="Male"
                          v-model="profileDetails.gender"
                        />
                        Male
                      </label>
                      <label>
                        <input
                          type="radio"
                          name="choice-radio"
                          value="Female"
                          v-model="profileDetails.gender"
                        />
                        Female
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div class="mb-3">
                <input
                  type="checkbox"
                  id="address"
                  class="form-check-input me-2"
                  v-model="profileDetails.pwd"
                />
                <label for="address">Person with Disability</label>
              </div>
              <div class="row">
                <div class="col-lg-6">
                  <p class="fs-5 text-primary fw-bold">Address</p>
                  <div class="">
                    <label for="name">Full Address</label>
                    <input
                      type="text"
                      class="form-control"
                      v-model="profileDetails.address"
                    />
                  </div>
                </div>
                <div class="col-lg-6 mb-3">
                  <p class="fs-5 text-primary fw-bold">Anthropometry</p>
                  <div class="d-lg-flex">
                    <div class="col-sm-3 col-lg-2 me-lg-2">
                      <label for="name">Weight</label>
                      <input
                        type="text"
                        class="form-control"
                        v-model="profileDetails.weight"
                      />
                    </div>
                    <div class="col-sm-3 col-lg-2">
                      <label for="name">Height</label>
                      <input
                        type="text"
                        class="form-control"
                        v-model="profileDetails.height"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <button class="btn btn-primary mb-3" @click="submitDetails">
                Save
              </button>

              <p class="fs-5 text-primary fw-bold">Contact Details</p>
              <div class="row mb-3">
                <div class="col-lg-6">
                  <label for="name">Mobile Number</label>
                  <input
                    oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                    type="tel"
                    placeholder="0916XXXXXXX"
                    class="form-control"
                    v-model="phone"
                    maxlength="11"
                    pattern="[0-9]*"
                  />
                </div>
                <div class="col-lg-6">
                  <label for="name">Email Address</label>
                  <input
                    type="email"
                    class="form-control"
                    v-model="profileDetails.email"
                  />
                </div>
              </div>
              <div v-if="showErrorPhone" class="text-danger col-lg-6 mb-3">
                Please enter a valid Philippine phone number starting with "09"
                and followed by 9 digits.
              </div>
              <button class="btn btn-primary" @click="submitContact">
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    </template>
  </SideBar>
</template>

<script setup>
import SideBar from "../components/common/SideBar";
import SimpleNavbarVue from "../components/common/SimpleNavbar";
import { ref, reactive, watchEffect, computed, watch, onMounted } from "vue";
import { useStore } from "vuex";
import axios from "axios";

const profileDetails = ref({
  lastName: "",
  firstName: "",
  middleName: "",
  birthMonth: "",
  birthDay: "",
  birthYear: "",
  age: "",
  pwd: false,
  gender: "",
  address: "",
  weight: "",
  height: "",
  mobile: "",
  email: "",
});

let validationErrors = reactive({
  lastName: false,
  firstName: false,
  middleName: false,
  birthMonth: false,
  birthDay: false,
  birthYear: false,
  age: false,
  pwd: false,
  gender: false,
  address: false,
  weight: false,
  height: false,
  email: false,
});

const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const years = [];
const currentYear = new Date().getFullYear();
const isSubmitContact = ref(false);
for (let year = currentYear - 10; year <= currentYear + 10; year++) {
  years.push(year);
}

const daysInMonth = reactive([]);

const updateDays = () => {
  if (profileDetails.value.birthMonth && profileDetails.value.birthYear) {
    const days = new Date(
      profileDetails.value.birthYear,
      profileDetails.value.birthMonth,
      0
    ).getDate();
    daysInMonth.length = 0;
    for (let day = 1; day <= days; day++) {
      const formattedDay = day < 10 ? `0${day}` : `${day}`;
      daysInMonth.push(formattedDay);
    }
    if (profileDetails.value.birthDay > days) {
      profileDetails.value.birthDay = "";
    }
  }
};

//Close active otp card
const onClickOutside = () => {
  isSubmitContact.value = false;
};

//Save buttons

const phNumberPattern = /^09\d{9}$/;
const showErrorPhone = ref(false);

const submitContact = (event) => {
  event.stopPropagation();

  if (phone.value && profileDetails.value.email) {
    isSubmitContact.value = !isSubmitContact.value;
    console.log(isSubmitContact.value);
  } else if (!phNumberPattern.test(phone.value)) {
    showErrorPhone.value = true;
    return;
  } else {
    alert("Please fill in all required fields.");
  }
};
const store = useStore();

const submitDetails = async () => {
  const configs = store.getters.getConfigs;

  try {
    const response = await axios.patch(
      `${configs.base_url}api/v1/patient_profile/update/${patient_id}`,
      {
        last_name: profileDetails.value.lastName,
        middle_name: profileDetails.value.middleName,
        first_name: profileDetails.value.firstName,
        birth_date: `${profileDetails.value.birthYear}-${profileDetails.value.birthMonth}-${profileDetails.value.birthDay}`,
        age: profileDetails.value.age,
        gender: profileDetails.value.gender,
        address: profileDetails.value.address,
        weight: profileDetails.value.weight,
        height: profileDetails.value.heights,
      }
    );
    console.log(response);
    store.commit("setProfileDetails", profileDetails);
  } catch (error) {
    alert(error);
  }
};

//For Resend OTP

const otp = ref("");
const phone = ref("");

const cooldownDuration = 60;
const isCooldown = ref(false);
const cooldownTime = ref(0);

const buttonText = computed(() => {
  return isCooldown.value ? `Resend OTP (${cooldownTime.value})` : "Resend OTP";
});

const handleResendOTP = () => {
  if (!isCooldown.value) {
    isCooldown.value = true;
    cooldownTime.value = cooldownDuration;

    const interval = setInterval(() => {
      cooldownTime.value -= 1;
      if (cooldownTime.value === 0) {
        clearInterval(interval);
        isCooldown.value = false;
      }
    }, 1000);
  }
};

const patient_id = localStorage.getItem("patient_id");

onMounted(async () => {
  const configs = store.getters.getConfigs;

  try {
    const response = await axios.get(
      `${configs.base_url}api/v1/patient_profile/detail/${patient_id}`
    );
    const date_split = response.data.birth_date.split("-");
    const modify_number = response.data.phone_number.replace("+63", "0");

    profileDetails.value.birthYear = date_split[0];
    profileDetails.value.birthMonth = parseInt(date_split[1]);
    profileDetails.value.birthDay = date_split[2];
    profileDetails.value.lastName = response.data.last_name;
    profileDetails.value.firstName = response.data.first_name;
    profileDetails.value.email = response.data.email;
    phone.value = modify_number;
    profileDetails.value.middleName = response.data.middle_name;
    profileDetails.value.age = response.data.age;
    profileDetails.value.pwd = response.data.disability;
    profileDetails.value.gender = response.data.gender;
    profileDetails.value.address = response.data.address;
    profileDetails.value.weight = response.data.weight;
    profileDetails.value.height = response.data.height;
  } catch (error) {
    console.error(error);
  }
});

//watch otp to input only numbers
watch(otp, (newValue, oldValue) => {
  if (newValue !== oldValue) {
    otp.value = newValue.replace(/\D/g, "");
  }
});

//watch mobile number to input only numbers
watch(phone, (newValue, oldValue) => {
  if (newValue !== oldValue) {
    phone.value = newValue.replace(/\D/g, "");
  }
});

watchEffect(updateDays);
</script>

<style scoped>
.card {
}

.backdrop {
  position: fixed;
  z-index: 9998;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: #33333377;
  backdrop-filter: blur(4px);
}
.otp-card {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 100;
}
.except {
  /* position: relative; */
  z-index: 9999;
}
</style>
