<template>
  <SimpleNavbar />
  <div class="bg-color">
    <MultiStep :currentStep="2" />

    <div class="container">
      <div class="row justify-content-center">
        <div class="col-10">
          <FormsItem />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FormsItem from "@/components/basic_details/FormsItem.vue";
import SimpleNavbar from "../components/common/SimpleNavbar.vue";
import MultiStep from "../components/MultiStep";

export default {
  name: "BasicDetailsView",
  components: {
    FormsItem,
    SimpleNavbar,
    MultiStep,
  },
};
</script>
