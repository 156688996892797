<script setup></script>

<template>
  <div>
    <nav class="navbar navbar-expand-lg bg-body-tertiary shadow-sm">
      <div class="container-fluid">
        <button
          class="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon"></span>
        </button>
        <div
          class="collapse navbar-collapse justify-content-center"
          id="navbarSupportedContent"
        >
          <a class="navbar-brand" href="#">
            <img
              src="@/assets/images/logo.png"
              alt="Logo"
              width="100"
              height="70"
              class="d-inline-block align-text-top"
            />
          </a>
          <a class="navbar-brand" href="#">
            <img
              src="@/assets/images/nameLogo.png"
              alt="Logo"
              width="130"
              height="20"
              class="d-inline-block align-text-end"
            />
          </a>
          <ul class="navbar-nav mb-2 mb-lg-0">
            <li class="nav-item">
              <a
                class="nav-link active fs-4 text-primary"
                aria-current="page"
                href="#"
                >Home</a
              >
            </li>
            <li class="nav-item">
              <a
                class="nav-link active fs-4 text-primary"
                aria-current="page"
                href="#"
                >About Us</a
              >
            </li>
            <li class="nav-item">
              <a
                class="nav-link active fs-4 text-primary"
                aria-current="page"
                href="#"
                >Services</a
              >
            </li>
            <li class="nav-item">
              <a
                class="nav-link active fs-4 text-primary"
                aria-current="page"
                href="#"
                >Doctors</a
              >
            </li>
            <li class="nav-item">
              <a
                class="nav-link active fs-4 text-primary"
                aria-current="page"
                href="#"
                >Contact Us</a
              >
            </li>
          </ul>
        </div>
      </div>
    </nav>
  </div>
</template>
