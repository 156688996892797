<template>
  <div class="row m-0">
    <div class="sidebar col-sm-12 col-md-2 col-lg-2 p-0" id="side_nav">
      <div class="sidebar-content">
        <button
          class="btn btn-primary d-md-none d-block mt-5"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#collapseExample"
          aria-expanded="false"
          aria-controls="collapseExample"
        >
          <i class="bi bi-list"></i>
        </button>

        <input
          type="file"
          @change="handleImageUpload"
          accept="image/*"
          ref="input"
        />
        <div class="collapse show" id="collapseExample">
          <div class="d-flex flex-column align-items-center pt-md-5">
            <div class="profile-image-container">
              <img class="profile-image mb-3" :src="imageUrl" alt="" />
              <div class="icon" @click="fileInputClick">+</div>
            </div>

            <p class="fs-5 fw-bold text-center mb-0 text-info mb-4">
              {{ full_name }}
            </p>
            <div class="d-flex flex-column fs-6 text-center col-sm-12 fw-bold">
              <a href="">Appointment Booking</a>
              <a href="">My MDR</a>
              <a href="">Schedules</a>
              <a href="">Profile</a>
              <a @click="submitLogOut" class="mt-5">Log Out</a>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="bg-color col-md-10 col-lg-10 p-0">
      <slot name="navbar"></slot>
      <slot name="body"></slot>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from "vue";
import axios from "axios";
import { useStore } from "vuex";
import router from "@/router";

const imageUrl = ref(require("@/assets/images/jenny.jpg"));
const full_name = ref("");
const input = ref(null);
const store = useStore();
const configs = store.getters.getConfigs;
const patient_id = localStorage.getItem("patient_id");
const access_token = localStorage.getItem("access_token");

//Function to handle image upload and update the profile image using PATCH request
const handleImageUpload = async (event) => {
  const file = event.target.files[0];
  console.log(file);

  if (file) {
    try {
      const formData = new FormData();
      formData.append("profile_pic", file);

      const response = await axios.patch(
        `${configs.base_url}api/v1/patient_profile/update/${patient_id}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${access_token}`,
          },
        }
      );
      console.log(response);
      imageUrl.value = response.data.profile_pic;
    } catch (error) {
      console.error("Error uploading image:", error);
    }
  }
};

//access dom element
const fileInputClick = () => {
  input.value.click();
};

const submitLogOut = async () => {
  store.commit("setisAuthenticated", false);
  localStorage.removeItem("refresh_token");
  localStorage.removeItem("access_token");

  const payload = {
    refresh: store.state.jwt_refresh_token,
  };
  try {
    const response = await axios.post(
      `${configs.base_url}api/v1/auth/patient_auth/logout/`,
      payload
    );
    console.log(response);
    router.push("/returning");
  } catch (error) {
    console.log(error);
  }
};

onMounted(async () => {
  try {
    const response = await axios.get(
      `${configs.base_url}api/v1/patient_profile/detail/${patient_id}`
    );
    imageUrl.value = response.data.profile_pic;
    full_name.value = `${response.data.first_name} ${response.data.last_name}`;
    localStorage.setItem("profile_details", JSON.stringify(response.data));

    console.log(response);
  } catch (error) {
    console.error(error);
  }
});
</script>

<style scoped>
@media (max-width: 750px) {
  .sidebar {
    min-height: 100px;
  }
}

@media (min-width: 768px) {
  .sidebar {
    min-height: 100vh;
    filter: drop-shadow(2px 5px 8px rgba(0, 0, 0, 0.5));
  }
}

.bi.bi-list {
  font-size: 30px;
}

.profile-image {
  border-radius: 50%;
  height: 100px;
  weight: 100px;
  display: block;
  object-fit: cover;
}

.profile-image-container {
  position: relative;
  display: inline-block;
}

div > a {
  list-style: none;
  color: white;
  text-decoration: none;
  width: 100%;
  height: 40px;
  padding: 7px;
}

.icon {
  position: absolute;
  bottom: 10px;
  right: 10px;
  font-size: 20px;
  color: black;
  cursor: pointer;
  background-color: rgba(252, 205, 64, 1);
  border-radius: 50%;
  width: 25px;
  height: 20px;
  text-align: center;
  padding-bottom: 25px;
}

div > a:hover {
  text-decoration: underline;
  background-color: rgba(252, 205, 64, 1);
  color: black;
}

#side_nav {
  background: rgba(23, 121, 201, 1);
}

input {
  display: none;
}
</style>
