<template>
  <div class="home">
    <RecaptchaCompVue />

    <MenuBar />
    <div class="container">
      <div class="main-card mt-5 mt-lg-0 m-5 m-lg-0">
        <div class="">
          <div class="text-center fs-2 fw-bold mb-4">
            <p class="">We would like to know few</p>
            <p>details about you!</p>
          </div>
          <CardItem class="p-5 p-lg-5 w-80 mx-auto">
            <template #body>
              <div class="text-center">
                <h2 class="text-primary fw-bolder">Patient Type</h2>
                <div class="row justify-content-center mt-3">
                  <div class="col-6">
                    <img
                      :src="
                        require(pType == 'nPatient' && pType != 'rPatient'
                          ? '@/assets/icons/PTypeActive.png'
                          : '@/assets/icons/PType.png')
                      "
                      alt="Patient Type Icon"
                      type="button"
                      @click="pType = 'nPatient'"
                    />
                    <div class="mt-1 text-primary fw-bold">
                      <p class="fs-6 m-0">New</p>
                      <p class="fs-6">Patient</p>
                    </div>
                  </div>
                  <div class="col-6">
                    <img
                      :src="
                        require(pType == 'rPatient' && pType != 'nPatient'
                          ? '@/assets/icons/PTypeActive.png'
                          : '@/assets/icons/PType.png')
                      "
                      alt="Patient Type Icon"
                      type="button"
                      @click="pType = 'rPatient'"
                    />
                    <div class="mt-1 text-primary fw-bold">
                      <p class="fs-6 m-0">Returning</p>
                      <p class="fs-6">Patient</p>
                    </div>
                  </div>
                </div>
                <div class="d-grid">
                  <button
                    class="btn btn-primary mt-3 fw-bold fs-5"
                    @click="redirectPatient"
                  >
                    Continue
                  </button>
                </div>
              </div>
            </template>
          </CardItem>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import MenuBar from "@/components/homepage/MenuBarItem.vue";
import CardItem from "@/components/common/CardItem.vue";
import { useRouter } from "vue-router";
import { ref } from "vue";
import RecaptchaCompVue from "../components/RecaptchaComp.vue";

export default {
  name: "HomeView",
  components: {
    MenuBar,
    CardItem,
    RecaptchaCompVue,
  },
  setup() {
    const pType = ref("nPatient");
    const router = useRouter();
    const redirectPatient = () => {
      if (pType.value == "nPatient") {
        router.push("/appointment");
      } else {
        router.push("/returning");
      }
    };

    return {
      pType,
      redirectPatient,
    };
  },
};
</script>

<style scoped lang="scss">
.home {
  background-image: url("../assets/images/Mobile_BG.png");
  height: 100vh;

  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

@media (min-width: 992px) {
  .main-card {
    position: absolute;

    top: 20%;
    left: 10%;
  }

  .home {
    background-image: url("../assets/images/BG.jpg");
    height: 100vh;

    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
}
</style>
