/* eslint-disable */
<script>
import { onMounted } from "vue";

import { useReCaptcha } from "vue-recaptcha-v3";

export default {
  name: "RecaptchaView",

  setup() {
    const { executeRecaptcha, recaptchaLoaded } = useReCaptcha();

    const recaptcha = async () => {
      // (optional) Wait until recaptcha has been loaded.
      await recaptchaLoaded();

      // Execute reCAPTCHA with action "login".
      const token = await executeRecaptcha("login");
      console.log(token);
      // Do stuff with the received token.a
    };
    onMounted(() => {
      recaptcha();
    });
    return {
      recaptcha,
    };
  },
};
</script>

<template><div></div></template>
