<template>
  <div
    :class="{ backdrop: isActiveStartCalendar || isActiveEndCalendar }"
  ></div>

  <SideBar>
    <template #navbar><SimpleNavbar /></template>
    <template #body>
      <div class="container mt-3">
        <div
          class="active-calendar except"
          v-show="isActiveStartCalendar === true"
          v-click-outside="onClickOutsideStartCalendar"
        >
          <DatePicker @dayclick="startDateClick" />
        </div>
        <div
          class="active-calendar except"
          v-show="isActiveEndCalendar === true"
          v-click-outside="onClickOutsideEndCalendar"
        >
          <DatePicker @dayclick="endDateClick" />
        </div>
        <div class="row align-items-start">
          <div class="col-lg-6">
            <div class="input-group w-80 mb-3">
              <input
                type="text"
                class="form-control p-2"
                aria-label="Text input with checkbox"
                placeholder="E-prescription"
                v-model="searchData"
              />
              <div class="input-group-text">
                <i class="bi bi-search" @click="searchButton"></i>
              </div>
            </div>
          </div>
          <div class="d-flex justify-content-lg-end col-lg-6">
            <div class="">
              <button
                class="button-date btn btn-info me-3 fw-bold"
                @click="startDateButton"
              >
                <i class="bi bi-calendar-week"></i> Start Date
              </button>
            </div>

            <p class="my-auto me-3 fw-bold">to</p>
            <div>
              <button
                class="button-date btn btn-info me-3 fw-bold"
                @click="endDateButton"
              >
                <i class="bi bi-calendar-week"></i>
                End Date
              </button>
            </div>
          </div>
        </div>
        <div class="d-flex justify-content-between mt-3">
          <p class="fs-4 text-primary fw-bold">List of E-prescription</p>
          <div class="d-flex">
            <i
              ><i
                class="bi bi-sort-up me-1 sort-button"
                @click="sortButton"
              ></i>
            </i>
            <p>Sort</p>
          </div>
        </div>
        <div class="table-responsive mt-3">
          <table class="table">
            <thead class="">
              <tr class="table-row">
                <th scope="col">Patient Name</th>
                <th scope="col">Date Consulted</th>
              </tr>
            </thead>
            <EPrescription
              v-for="(appointment, index) in appointmentData"
              :key="index"
              :index="index"
              :appointment="appointment"
              :profile_data="profileData"
            />
          </table>
        </div>
      </div>
      <div></div>
    </template>
  </SideBar>
</template>

<script setup>
import SideBar from "../components/common/SideBar";
import SimpleNavbar from "../components/common/SimpleNavbar";
import EPrescription from "../components/PdfTemplate";
import { ref, onMounted } from "vue";
import { DatePicker } from "v-calendar";
import { useStore } from "vuex";
// import { jsPDF } from "jspdf";

import axios from "axios";

const store = useStore();

const searchData = ref("");
const isActiveStartCalendar = ref(false);
const isActiveEndCalendar = ref(false);

const dateData = ref({
  startDate: "",
  endDate: "",
});

const appointmentData = ref([]);
const profileData = ref({});
const searchButton = async () => {
  // const headers = {
  //   Authorization: `Bearer ${token}`,
  // };

  // try {
  //   const response = await axios.get(
  //     `${configs.base_url}api/v1/prescription/search/?doctor=${}patient=${}&appointment=${}`,
  //     { headers }
  //   );
  //   console.log(response);
  //   patientsMDR.value = response.data.results;
  // } catch (error) {
  //   console.log(error);
  // }
  console.log();
};

const startDateButton = () => {
  event.stopPropagation();

  isActiveStartCalendar.value = !isActiveStartCalendar.value;
  console.log();
};
const endDateButton = () => {
  event.stopPropagation();

  isActiveEndCalendar.value = !isActiveEndCalendar.value;

  console.log();
};
const onClickOutsideStartCalendar = () => {
  isActiveStartCalendar.value = false;

  console.log();
};
const onClickOutsideEndCalendar = () => {
  isActiveEndCalendar.value = false;

  console.log();
};

const startDateClick = (day) => {
  dateData.value.startDate = day.id;
};
const endDateClick = (day) => {
  dateData.value.endDate = day.id;
};

const sortButton = () => {
  console.log();
};
const token = localStorage.getItem("access_token");

onMounted(async () => {
  const configs = store.getters.getConfigs;
  const headers = {
    Authorization: `Bearer ${token}`,
  };
  try {
    const response = await axios.get(
      `${
        configs.base_url
      }api/v1/appointment/my-mdr-list/?page_size=${10}&page=${1}`,
      { headers }
    );
    console.log(response.data);
    appointmentData.value = response.data.results;
    const data = localStorage.getItem("profile_details");
    profileData.value = JSON.parse(data);
  } catch (error) {
    console.error(error);
  }
});
</script>

<style scoped>
.bi.bi-search {
  cursor: pointer;
}

.input-group-text:hover {
  background-color: #fccd40;
}
.input-group-text:active {
  text-shadow: 2px 2px;
}

.btn-info {
  border: 1px solid rgba(196, 196, 196, 0.5);
  border-radius: 16px;
  background-color: rgba(255, 255, 255, 1);
}

.btn-info:hover {
  border: 1px solid rgba(196, 196, 196, 0.5);
  border-radius: 16px;
  background-color: #fccd40;
}

.table-row > th {
  background-color: rgba(23, 121, 201, 1);
  color: rgba(255, 255, 255, 1);
  border: none;
}

.sort-button {
  cursor: pointer;
}

.sort-button:hover {
  color: #1779c9;
}
.sort-button:active {
  color: black;
}

.active-calendar {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 100;
}

.except {
  /* position: relative; */
  z-index: 9999;
}

.backdrop {
  position: fixed;
  z-index: 9998;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: #33333377;
  backdrop-filter: blur(4px);
}
input[type="text"],
textarea {
  background-color: #d1d1d1;
}

input[type="text"]:focus {
  background-color: white;
}
</style>
