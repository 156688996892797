<template>
  <SideBar>
    <template #navbar><SimpleNavbar /></template>

    <template #body>
      <div class="container">
        <div class="w-80 w-sm-50 mx-auto mt-2">
          <div class="d-flex justify-content-around me-5">
            <p
              :class="{
                'text-primary': isActiveTextMonth,
                'default-text': !isActiveTextMonth,
              }"
              class="fw-bold fs-4"
              @click="handleClick('month')"
            >
              Month
            </p>
            <p
              :class="{
                'text-primary': isActiveTextWeek,
                'default-text': !isActiveTextWeek,
              }"
              class="fw-bold fs-4"
              @click="handleClick('week')"
            >
              Week
            </p>
            <p
              :class="{
                'text-primary': isActiveTextDay,
                'default-text': !isActiveTextDay,
              }"
              class="fw-bold fs-4"
              @click="handleClick('day')"
            >
              Day
            </p>
          </div>
        </div>
        <FullCalendar
          class="calendar"
          :options="calendarOptions"
          :key="calendarKey"
        />
      </div>
    </template>
  </SideBar>
</template>

<script setup>
import { ref, onMounted } from "vue";
import SideBar from "../components/common/SideBar";
import SimpleNavbar from "../components/common/SimpleNavbar";
import FullCalendar from "@fullcalendar/vue3";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import { useStore } from "vuex";
import axios from "axios";

//values to hover
const isActiveTextMonth = ref(true);
const isActiveTextWeek = ref(false);
const isActiveTextDay = ref(false);

//For vue reactivity because fullcalendar does not work with vue reactivity
const calendarKey = ref(0);

const calendarOptions = ref({
  plugins: [dayGridPlugin, interactionPlugin],
  initialView: "dayGridMonth",
  //we modified headerToolbar css by overriding
  headerToolbar: {
    start: "prev",
    center: "title",
    end: "next",
  },
  events: [],
  eventContent: function (arg) {
    return {
      html: arg.event.title.replace(/\n/g, "<br>"),
    };
  },
});

const handleClick = (view) => {
  isActiveTextMonth.value = view === "month";
  isActiveTextWeek.value = view === "week";
  isActiveTextDay.value = view === "day";
  if (isActiveTextMonth.value) {
    calendarOptions.value.initialView = "dayGridMonth";

    calendarKey.value += 1;
  } else if (isActiveTextDay.value) {
    calendarOptions.value.initialView = "dayGridDay";
    calendarKey.value += 1;
  } else if (isActiveTextWeek.value) {
    calendarOptions.value.initialView = "dayGridWeek";
    calendarKey.value += 1;
  }
};
const token = localStorage.getItem("access_token");

const fetchAppointments = async () => {
  const store = useStore();
  const configs = store.getters.getConfigs;

  const headers = {
    Authorization: `Bearer ${token}`,
  };

  try {
    const response = await axios.get(
      `${configs.base_url}api/v1/appointment/list/`,
      { headers }
    );

    const appointments = response.data;
    //Get the appointments into FullCalendar event objects
    const events = appointments.map((appointment) => ({
      title: `Consultation\n ${appointment.appointment_timeslot}`,
      date: appointment.appointment_date,
    }));
    // Update calendarOptions with the new events
    calendarOptions.value.events = events;
    console.log(response);
  } catch (error) {
    console.log(error);
  }
};

onMounted(async () => {
  //load data when the component is mounted
  await fetchAppointments();
});
</script>

<style>
.default-text {
  color: rgba(196, 196, 196, 1);
}
.calendar {
  height: 100vh;
}
.fc .fc-toolbar {
  display: flex;
  justify-content: center;
  background-color: rgba(23, 121, 201, 1);
  color: rgba(255, 255, 255, 1);
}
.fc .fc-toolbar-title {
  font-size: 18px;
  font-family: "Poppins", sans-serif;
  font-weight: bold;
}

.fc .fc-button-primary {
  background-color: rgba(23, 121, 201, 1);
  border: none;
  color: rgba(255, 255, 255, 1);
}

.fc .fc-button-primary:hover {
  background-color: rgba(23, 121, 201, 1);
  border: none;
  color: rgba(255, 255, 255, 1);
}

.fc .fc-button-primary:active {
  background-color: rgba(23, 121, 201, 1);
  border: none;
  color: rgba(255, 255, 255, 1);
}

.fc-toolbar-chunk {
  margin-right: 50px;
}

.fc-col-header-cell {
  color: black;
  text-decoration: none;
}

.fc th {
  text-decoration: none;
}

:root {
  --fc-button-active-bg-color: rgba(23, 121, 201, 1);
  --fc-button-active-border-color: rgba(23, 121, 201, 1);
  --fc-neutral-text-color: black;
}

.fc .fc-col-header-cell-cushion {
  display: inline-block;
  padding: 2px 4px;
  color: black;
  text-decoration: none;
}

.fc .fc-daygrid-day-number {
  padding: 2px;
  position: relative;
  z-index: 4;
  color: black;
  text-decoration: none;
}

.fc .fc-daygrid-day-frame {
  min-height: 100%;
  position: relative;
  background-color: rgba(255, 255, 255, 1);
}
.fc-direction-ltr .fc-daygrid-event.fc-event-end,
.fc-event-main-frame {
  flex-direction: column;
  text-align: center;
}

.fc-event-desc,
.fc-event-title {
  white-space: break-spaces;
}

.fc-daygrid-day-frame {
  padding: 10px;
}
</style>
