<script setup>
import { ref, defineProps } from "vue";

import StepProgress from "vue-step-progress";
import "vue-step-progress/dist/main.css";

defineProps({
  currentStep: String,
});

const mySteps = ref([
  "Appointment Details",
  "Pre-assessment",
  "Basic Details",
  "Payment",
]);
</script>

<template>
  <div class="step-form mx-auto">
    <step-progress
      class="step"
      :steps="mySteps"
      :current-step="currentStep"
      active-color="#1779C9"
      passive-color="#799DBA"
      line-thickness="4"
      icon-class="bi bi-check"
    ></step-progress>
  </div>
</template>

<style lang="sass">

.step-form
    width:50%


.step-progress__step,
.step-progress__step::after
  width: 40px
  height: 40px
  border-width: 3px
  span
    font-size: 18px

    @media (max-width: 767px)
      font-size: 14px

.step-progress__step span
  font-size: 14px
  line-height: 3

  @media (max-width: 767px)
    font-size: 12px

.step-progress__step-label
  line-height: 0
  font-size: 18px

  @media (max-width: 767px)
    font-size: 8px
</style>
