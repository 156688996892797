<template>
  <SideBar>
    <template #navbar><SimpleNavbar /></template>

    <template #body>
      <div class="main-container">
        <ReturningMultiStep :currentStep="2" />

        <div class="container">
          <div class="card p-3 mb-4">
            <p class="text-primary fs-4 fw-bold">Payment Summary</p>
            <hr />
            <div class="row">
              <div class="d-flex justify-content-between flex-nowrap col-lg-4">
                <div>
                  <p class="text-primary fs-5 fw-bold">Jenny Mercado</p>
                  <p>Dentistry</p>
                  <i>Star 4.8</i>
                </div>
                <img
                  class="doctor-image order-1 me-sm-5"
                  src="../assets/images/jenny.jpg"
                  alt=""
                />
              </div>
              <div class="col-lg-4">
                <p class="text-primary fs-5 fw-bold">Appointment Schedule</p>
                <div class="d-inline-flex">
                  <p class="text-primary fw-bold">Date:&nbsp;</p>
                  <p>January 10, 2001</p>
                </div>
                <br />
                <div class="d-inline-flex">
                  <p class="text-primary fw-bold">Time:&nbsp;</p>
                  <p>10:00 am - 11:00 am</p>
                </div>
              </div>
              <div class="col-lg-4">
                <p class="text-primary fs-5 fw-bold">Consulation Fee</p>
                <p class="fw-bold fs-5">Php 1,200</p>
              </div>
            </div>
          </div>
        </div>
        <div class="container">
          <div class="card p-3">
            <div class="d-inline-flex justify-content-between">
              <p>Select option to pay</p>
              <p class="fw-bold">Php 1,200</p>
            </div>
            <div class="d-lg-inline-flex mx-auto">
              <div class="form-check">
                <input
                  class="form-check-input mt-3"
                  type="radio"
                  name="flexRadioDefault"
                  id="flexRadioDefault1"
                />
                <label class="form-check-label me-lg-3" for="flexRadioDefault1">
                  <img src="../assets/images/Paypal_button.svg" />
                </label>
              </div>
              <div class="form-check">
                <input
                  class="form-check-input mt-3"
                  type="radio"
                  name="flexRadioDefault"
                  id="flexRadioDefault2"
                  checked
                />
                <label class="form-check-label" for="flexRadioDefault2">
                  <img src="../assets/images/Gcash_button.svg" />
                </label>
              </div>
            </div>
            <p class="mx-auto col-sm-8 text-center">
              Click the button below to redirect to PayPal’s website to complete
              the transaction.
            </p>
            <button
              @click="submitButton"
              class="btn btn-primary col-sm-12 col-lg-2 mx-auto"
            >
              Continue
            </button>
          </div>
        </div>
      </div>
    </template>
  </SideBar>
</template>

<script setup>
import axios from "axios";
import { useStore } from "vuex";
import SideBar from "../components/common/SideBar";
import SimpleNavbar from "../components/common/SimpleNavbar";
import ReturningMultiStep from "../components/ReturningMultiStep";

const store = useStore();
const configs = store.getters.getConfigs;

const submitButton = async () => {
  const appointment_detail = JSON.parse(localStorage.getItem("doctor_detail"));
  const pre_assessment_details = JSON.parse(
    localStorage.getItem("pre_assessment")
  );
  const basic_details = JSON.parse(localStorage.getItem("basic_details"));
  try {
    //Appointment API
    const firstResponse = await axios.post(
      `${configs.base_url}api/v1/appointment/create/`,
      {
        appointment_date: appointment_detail.appointment_date,
        appointment_timeslot: appointment_detail.appointment_timeslot,
        doctor: appointment_detail.doctor,
      }
    );
    console.log(firstResponse.data, "first_response");
    //Pre-assessment API
    const secondResponse = await axios.post(
      `${configs.base_url}api/v1/preassessment/create/`,
      {
        primary_symptoms: pre_assessment_details.primary_symptoms,
        current_medical_condition:
          pre_assessment_details.current_medical_condition,
        allergies: pre_assessment_details.allergies,
        alcohol_frequency: 0,
        is_smoker: pre_assessment_details.is_smoker,
        current_medication: pre_assessment_details.current_medication,
        appointment: firstResponse.data.id,
      }
    );
    console.log(firstResponse.data.id, "appointment_id");
    console.log(secondResponse.data, "second_response");
    //Payment API
    const thirdResponse = await axios.post(
      `${configs.base_url}api/v1/payment/create/`,
      {
        patient_details: {
          last_name: basic_details.last_name,
          first_name: basic_details.first_name,
          middle_name: basic_details.middle_name,
          birth_date: "1993-04-21",
          age: basic_details.age,
          sex: basic_details.gender,
          address: basic_details.full_address,
          weight: basic_details.weight,
          height: basic_details.height,
          mobile_number: basic_details.mobile_number,
          email: basic_details.email,
        },
        booking_details: firstResponse.data.id,
        amount: appointment_detail.fee,
        status: "PENDING",
      }
    );
    const payment_id = thirdResponse.data.id;
    console.log(thirdResponse.data, "third_response");

    const fourthResponse = await axios
      .post(
        `${configs.base_url}api/v1/payment/create-checkout-session/${payment_id}/`
      )
      .then((response) => {
        window.location.assign(response.data.invoice);
      });

    console.log(fourthResponse.data, "fourth_response");
  } catch (error) {
    console.log(error);
  }
};
</script>

<style scoped>
.doctor-image {
  border-radius: 50%;
}
</style>
