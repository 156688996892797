<template>
  <MenuBarItem />

  <div class="main-container">
    <div class="container-sm">
      <ReturningCardComp v-if="returningCard" />
      <OtpCardComp v-if="otpCard" />
    </div>
  </div>
</template>

<script setup>
import { ref } from "vue";

import MenuBarItem from "../components/homepage/MenuBarItem.vue";
import ReturningCardComp from "../components/otp/ReturningCardComp";
import OtpCardComp from "../components/otp/OtpCardComp";

const returningCard = ref(true);
const otpCard = ref(false);
</script>

<style scoped>
.main-container {
  background-image: url("../assets/images/Mobile_BG.png");
  min-height: 800px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

a {
  line-height: normal;
  text-decoration: none;
  list-style: none;
  color: black;
}

@media (min-width: 576px) {
  .main-container {
    background-image: url("../assets/images/Mobile_BG.png");
    min-height: 800px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
}

@media (min-width: 992px) {
  .main-container {
    background-image: url("../assets/images/BG.jpg");
    min-height: 800px;

    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .otp-container {
    position: absolute;
    top: 30%;
    left: 10%;
  }
}
</style>
